import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const SgPage = () => (
    <Layout>
        <Seo 
            title="Overseas student accommodation marketplace startup case study" 
            description="Australian startup case study - the development services completed for Studentguru - an overseas student marketplace startup."
        />

        <Hero
            h1="Student marketplace startup case study"  
            h2="Studentguru"
        />

        <section className="bg-noise sg section-pad-sm">
            <div className="container" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/studentguru-main.png"
                        alt="Screens of the Studentguru website and application"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Studentguru provides a marketplace to overseas students, who are coming to Australia to study, where they can find student accommodation, open a bank account, buy a mobile phone plan and secure their required overseas health cover (OSHC) all in one place.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/vue-developer/" className="btn btn-lg btn-line">Vue.js development</Link>
                                    <Link to="/django-developer-sydney-canberra/" className="btn btn-lg btn-line">Python / Django development</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS infrastructure</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Developed using Vue.js, Python / Django and AWS, the project includes:</p>
                                    <ul>
                                        <li>a student accommodation real estate portal where users can search for rental accommodation</li>
                                        <li>a map where users can find accommodation close to their chosen college or university</li>
                                        <li>ecommerce functionality for purchasing mobile plans, where the user can choose to pay in 1 of 4 different currencies</li>
                                        <li>ecommerce functionality for calculating the cost of OSHC, and purchasing it</li>
                                        <li>multi language setting where users can toggle between English and Mandarin</li>
                                        <li>the ability for Admin to generate discount codes for use in the shopping cart</li>
                                        <li>an Agents referral platform</li>
                                        <li>a student dashboard</li>
                                        <li>an Admin interface for managing the application</li>
                                    </ul>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Studentguru website.</p>
                                    <a href="https://studentguru.com.au/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design or web application development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default SgPage